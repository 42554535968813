<template>
	<div class="m-expert" v-loading="loading">
		<div class="m-where">
			<div class="m-info">
				<div class="m-info-img">
					<img :src="info.image" />
				</div>
				<div class="m-info-con">
					<p class="m-info-name">{{info.name || ''}}</p>
					<p class="u-m-t-20">
						<span class="m-info-font1">主讲课程：</span>
						<span class="m-info-font2">{{info.master_course ? info.master_course.join('、') : '--'}}</span>
					</p>
				</div>
			</div>
			<p class="m-font3 u-m-t-54">讲师介绍：</p>
			<p class="m-font3">{{info.synopsis ||''}}</p>
		</div>
	</div>
</template>

<script>
	import {getTeacherDetail} from "@/api/expert.js"
	export default {
		data(){
			return {
				loading:false,
				info:{}
			}
		},
		mounted() {
			this.getInfo()
		},
		methods:{
			async getInfo(){
				let res = await getTeacherDetail({id:this.$route.query.id})
				if(res.code == 200){
					this.info= res.data
				}else{
					this.$message.error(res.message)
					this.$router.back()
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.m-expert{
		background: #F7F7F7;
		min-height: 500px;
		background-image: url(../assets/img/common/zhuanjia-bg2.png);
		background-repeat: no-repeat;
		background-size: 1920px 430px;
		padding-top: 388px;
		padding-bottom: 80px;
		.m-font3{
			font-size: 16px;
			color: #404040;
			line-height: 30px;
		}
		.m-where{
			width: 1200px;
			margin: 0 auto;
			padding: 56px;
			border-radius: 5px;
			background: #fff;
			.m-info{
				display: flex;
				.m-info-name{
					font-size: 22px;
					color: #222;
					font-weight: 800;
					line-height: 22px;
				}
				.m-info-font1{
					font-size: 16px;
					color: #222;
					line-height: 16px;
				}
				.m-info-font2{
					font-size: 16px;
					line-height: 16px;
					color: #666;
				}
				.m-info-img{
					display: flex;
					justify-content: center;
					align-content: center;
					width: 174px;
					height: 232px;
					background: #BEBEBE;
					overflow: hidden;
					img{
						height: 100%;
						width: auto;
					}
				}
				.m-info-con{
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					margin-left: 35px;
				}
			}
		}
		
	}
</style>
